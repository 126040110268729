import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TicketSubmissionPrizeDTO } from '@pbl/pbl-react-core/lib/models/ticket';
import { PrizeType } from '@pbl/pbl-react-core/lib/models/ticket/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import { config } from '@pbl/pbl-react-web-components/lib/package';
import TicketCard from '@pbl/pbl-react-web-components/lib/ticket-entry/TicketCard';
import TicketPendingModal from '@pbl/pbl-react-web-components/lib/ticket-entry/TicketPendingModal';
import TicketValidatedModal from '@pbl/pbl-react-web-components/lib/ticket-entry/TicketValidatedModal';
import TicketHelpModal from '@pbl/pbl-react-web-components/lib/ticket-entry/v2/TicketHelpModal';
import styles from 'assets/jss/modules/ticket/TicketScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { getTicketHelpTabs, getTicketTab } from 'config/ticket';
import * as React from 'react';
import ReactGA, { EventArgs } from 'react-ga';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearMessages, showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { displayConfetti } from 'redux/reducers/confetti/actions';
import { endGame, skipGame, startGame } from 'redux/reducers/interactive-games/actions';
import { fetchUserPoints } from 'redux/reducers/loyalty/actions';
import { clearErrors, clearPrizes, enterTicket, fetchDrawsByIds, reset } from 'redux/reducers/ticket/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

export interface ITicketScreenProps extends StateProps, DispatchProps, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
}

interface ITicketScreenState {
  loading: boolean;
  initalTab: string;
  showSampleTicket: boolean;
  showSuccessModal: boolean;
  showPendingModal: boolean;
  isQuickCardVisible: boolean;
  pendingPoints: number | null;
  analyticEvent: EventArgs;
  showConsentText: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({
  ticket: { errorMessage, loading, prizes, graphicImageUrl },
  loyalty: { userPoints },
  feature: { authorizedFlags },
  authentication: { accessToken, account }
}: IRootState) => ({
  errorMessage,
  loading,
  prizes,
  graphicImageUrl,
  authorizedFlags,
  userPoints,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email
});

const mapDispatchToProps = {
  enterTicket,
  reset,
  clearErrors,
  clearPrizes,
  showMessageBar,
  fetchUserPoints,
  fetchDrawsByIds,
  isAuthenticated,
  startGame,
  endGame,
  skipGame,
  clearMessages,
  displayConfetti
};

const INITIAL_STATE: ITicketScreenState = {
  loading: false,
  initalTab: '',
  showConsentText: true,
  showSampleTicket: false,
  showSuccessModal: false,
  showPendingModal: false,
  pendingPoints: 0,
  isQuickCardVisible: false,
  analyticEvent: {
    action: '/ticket-entry',
    category: 'ticket-submit',
    label: 'Ticket triggered entry from /ticket-entry'
  }
};

class TicketScreen extends React.Component<ITicketScreenProps, ITicketScreenState> {
  constructor(props: ITicketScreenProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  public componentWillMount() {
    const { authorizedFlags } = this.props;
    const enableQuickCard = !!authorizedFlags && authorizedFlags.includes('QUICKCARD_V1');
    const enableQuickDraw = !!authorizedFlags && authorizedFlags.includes('QUICKDRAW_V1');
    if (enableQuickCard || enableQuickDraw) {
      config.internalConfig.TICKET_TABS_DATA = getTicketTab(enableQuickCard, enableQuickDraw);
    }
  }

  public async componentDidMount() {
    setTimeout(() => {
      const element = document.querySelector<HTMLDivElement>('.slick-track');
      if (element) {
        element.classList.add(`slick-track-hack`);
      }
      setTimeout(() => {
        element?.classList.remove(`slick-track-hack`);
      }, 200);
    }, 500);

    document.title = 'Enter Ticket';
    scrollToTheTop();
    await this.props.isAuthenticated();
    const { isLoggedIn, location, authorizedFlags } = this.props;
    if (!isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    const enableQuickCard = !!authorizedFlags && authorizedFlags.includes('QUICKCARD_V1');
    const enableQuickDraw = !!authorizedFlags && authorizedFlags.includes('QUICKDRAW_V1');
    if (enableQuickCard || enableQuickDraw) {
      this.setState({ isQuickCardVisible: true });
    }

    // @ts-ignore
    if (location && location.state && !!location.state.from) {
      const { analyticEvent } = this.state;
      // @ts-ignore
      this.setState({
        analyticEvent: {
          ...analyticEvent,
          // @ts-ignore
          action: location.state.from,
          // @ts-ignore
          label: `Ticket triggered entry from ${location.state.from}`
        }
      });
    }
  }

  public componentWillUnmount() {
    this.setState(INITIAL_STATE);
    this.props.clearMessages();
  }

  public onSubmitTicket = async (ticketBarcodeNumber: string, validationNumber?: string, tabKey?: string) => {
    const ticketNumber =
      !!tabKey && tabKey === 'quickCard' ? '639299'.concat(ticketBarcodeNumber).toUpperCase() : ticketBarcodeNumber.toUpperCase();
    await this.setState({ loading: true });
    await this.props.enterTicket(ticketNumber, validationNumber ? validationNumber.toUpperCase() : '');
    await this.props.fetchUserPoints();
    await this.props.fetchDrawsByIds();
    await this.setState({ loading: false });
    await this.modalMessage();
  };

  public modalMessage = async () => {
    const { errorMessage, prizes } = this.props;

    if (errorMessage == null && prizes != null) {
      const pendingPoints = prizes.filter(
        (x: TicketSubmissionPrizeDTO) => x.prizeType === PrizeType.LOYALTY_POINTS && x.status === 'PENDING'
      );
      const pendingEntries = prizes.filter((x: TicketSubmissionPrizeDTO) => x.prizeType === PrizeType.ENTRY && x.status === 'PENDING');
      const digitalReveals = prizes.filter((x: TicketSubmissionPrizeDTO) => !!x.token);

      const showPendingOnly =
        pendingPoints &&
        pendingPoints.length > 0 &&
        pendingPoints[0].amount &&
        (!pendingEntries || pendingEntries.length === 0) &&
        (!digitalReveals || digitalReveals.length === 0);

      if (showPendingOnly) {
        await this.setState({
          showPendingModal: true,
          pendingPoints: pendingPoints[0].amount
        });
      } else {
        await this.setState({
          showSuccessModal: true
        });
      }
      await ReactGA.event(this.state.analyticEvent);
    } else if (errorMessage != null) {
      let errormessage: string;
      if (!errorMessage.errorKey && !!errorMessage.title) {
        errormessage = errorMessage.title;
      } else if (!errorMessage.errorKey && !!errorMessage.error) {
        errormessage = errorMessage.error;
      } else {
        errormessage = errorMessage.errorKey;
      }
      this.props.showMessageBar({ message: errormessage, type: 'error' });
    }
  };

  public onDismissSuccessModal = () => {
    this.props.clearErrors();
    this.props.clearPrizes();
    this.setState({ showSuccessModal: false });
  };
  public onDismissPendingModal = () => {
    this.props.clearErrors();
    this.props.clearPrizes();
    this.setState({ showPendingModal: false });
  };
  private onNavigate = () => {
    this.props.history.push('/games');
  };

  public showSampleHelpTicket = (tabKey: string) => {
  // const ticketTabSelected = config.internalConfig.TICKET_TABS_DATA.find((item: ITicketTab) => item.tabKey === tabKey);
    this.setState({ initalTab: tabKey, showSampleTicket: true });
  };

  public onDismissSampleModal = async () => {
    this.setState({ showSampleTicket: false });
    await this.props.isAuthenticated();
  };

  private getGameData = (digitalRevealToken: string) => {
    this.props.startGame(digitalRevealToken);
  };

  private gameEnded = async (digitalRevealToken: string) => {
    this.props.endGame(digitalRevealToken);
  };

  private gameSkipped = async (digitalRevealToken: string) => {
    this.props.skipGame(digitalRevealToken);
  };

  private startGame = async (digitalRevealToken: string) => {
    this.props.startGame(digitalRevealToken);
  };

  private onClick = () => {
    const path = '/settings/preferences';
    this.props.history.push(path, {
      currentRoute: path,
      currentRouteName: 'Communication Preferences'
    });
  };

  private onViewSuperBonusProgress = () => {
    const { prizes } = this.props;
    const sb = prizes && prizes[0].superBonuses;
    const superBonusKey = sb && sb[0].superBonusKey;
    this.onDismissSuccessModal();
    this.props.history.push('/collect-them-all/' + superBonusKey);
  };

  public render() {
    const { classes, prizes, graphicImageUrl, t } = this.props;
    const { loading, showSuccessModal, showPendingModal } = this.state;

    return (
      <div className={classes.root}>
        <Grid container={true} className={classNames(classes.ticketContainer, classes.center)}>
          <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
            {loading && <AppSpinner label={'Loading...'} />}
            <TicketPendingModal
              onDismiss={this.onDismissPendingModal}
              showSuccessModal={showPendingModal && !showSuccessModal}
              pendingPoints={this.state.pendingPoints || 0}
            />
            <TicketValidatedModal
              onDismiss={this.onDismissSuccessModal}
              showSuccessModal={showSuccessModal}
              prizes={prizes}
              graphicImageUrl={graphicImageUrl}
              getGameData={this.getGameData}
              startGame={this.startGame}
              endGame={this.gameEnded}
              skipGame={this.gameSkipped}
              navigateToSuperBonusDetails={this.onViewSuperBonusProgress}
              showTicketDisclaimer={constants.SHOW_TICKETDISCLAIMER}
              showDigitalRevealSummaryOnEnd={constants.SHOW_DIGITAL_SUMMARY_AT_END}
              displayConfetti={this.props.displayConfetti}
            />
           {this.state.showSampleTicket && <TicketHelpModal
              visible={this.state.showSampleTicket}
              onDismiss={this.onDismissSampleModal}
              initialTabKey={this.state.initalTab}
              displayVideo={true}
              tabs={getTicketHelpTabs(this.state.initalTab)}
            />}

            <Hidden xsDown={true}>
              <Typography variant="h4" component="h1" gutterBottom={true} color={'textPrimary'} className={classes.ticketTitle}>
                Enter Ticket
              </Typography>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6" component="h1" gutterBottom={true} color={'textPrimary'} style={{ textAlign: 'center' }}>
                Enter Ticket
              </Typography>
            </Hidden>
            <Hidden smUp={true}>
              <TicketCard
                loading={this.state.loading}
                showSampleTicketHelpImage={this.showSampleHelpTicket}
                onTicketSubmit={this.onSubmitTicket}
                navigateToGames={this.onNavigate}
                showTicketCardView={constants.SHOW_TICKETCARDVIEW}
                isMobileView={constants.SHOW_TICKETCARDVIEW}
                isQuickCardVisible={this.state.isQuickCardVisible}
              />
            </Hidden>
            <Hidden xsDown={true}>
              <TicketCard
                loading={this.state.loading}
                showSampleTicketHelpImage={this.showSampleHelpTicket}
                onTicketSubmit={this.onSubmitTicket}
                navigateToGames={this.onNavigate}
                showConsentText={this.state.showConsentText}
                showTicketCardView={constants.SHOW_TICKETCARDVIEW}
                isQuickCardVisible={this.state.isQuickCardVisible}
              />
            </Hidden>
          </Grid>
        </Grid>
        <Grid container={true} className={classNames(classes.ticketBottomContainer, classes.center)}>
          <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" align={'center'} component={'p'}>
              {t('ticket.disclaimerText1')}
            </Typography>
            <Typography variant="subtitle2" align={'center'} className={classes.disclaimerText2} component={'p'}>
              <Trans i18nKey="ticket.disclaimerText2">
                a
                <Typography
                  component={'a'}
                  onClick={this.onClick}
                  target="_self"
                  color={'primary'}
                  variant="subtitle2"
                  style={{ cursor: 'pointer' }}
                >
                  Communication Preferences
                </Typography>
                a
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withRouter(withTheme(withStyles(styles)(TicketScreen)))));
