import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IActivity, IReward, IRewardCategory } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { AppSpinner } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import homeScreenEarnActivities from 'config/homeScreenEarnActivities';
import utils from 'modules/earn/utils';
import DrawsSection from 'modules/home/components/DrawsSection';
import EarnSection from 'modules/home/components/EarnSection';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearLoading, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  fetchHomePageProgressivePrizes,
  fetchHomePageProgressivePromotions,
  fetchHomePagePromotions,
  selectPromotion
} from 'redux/reducers/draws/actions';
import {
  fetchActivities,
  fetchRewardCategories,
  fetchRewards,
  fetchUserActivitiesWithInterests,
  fetchUserRewards,
  getActivity
} from 'redux/reducers/loyalty/actions';
import { getAdvertisements } from 'redux/reducers/media/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import displayOutageBanner from 'utils/outageUtils';
import FeaturedSlider from './components/FeaturedSlider';
import InformationOnlySection from './components/InformationOnlySection';
import IntroSection from './components/IntroSection';
import LuckyLoungeSection from './components/LuckyLoungeSection';
import RewardsSection from './components/RewardsSection';

type PropsConnected = ConnectedProps<typeof connector>;

export interface IHomeScreenProps extends PropsConnected, RouteComponentProps, WithStyles<typeof styles> {
  theme: Theme;
}

class HomeScreen extends React.Component<IHomeScreenProps, { fetching: boolean }> {
  private prizeCheck: any | null = null;
  constructor(props: IHomeScreenProps) {
    super(props);
    this.state = { fetching: false };
  }

  public async componentDidMount() {
    document.title = 'Home Page';

    const { showOutageBanner, body, displayTime } = await displayOutageBanner();
    if (showOutageBanner) {
      this.props.showMessageBar({
        type: 'outage_warning',
        message: body,
        messageTimeout: displayTime
      });
    }

    this.setState({ fetching: true });
    await this.props.isAuthenticated();
    await this.getData();
    this.prizeCheck = setInterval(this.updateProgressivePrize, constants.HOME_SCREEN_FETCH_DRAW_INTERVAL);
    this.setState({ fetching: false });
  }

  public getData = async (): Promise<void> => {
    const requests: any = [];
    if (this.props.loggedIn) {
      requests.push(await this.props.fetchUserRewards());
      requests.push(await this.props.fetchUserActivitiesWithInterests());
    } else {
      requests.push(await this.props.fetchRewards());
      requests.push(await this.props.fetchActivities());
    }
    requests.push(this.props.getAdvertisements());
    requests.push(await this.props.fetchHomePageProgressivePromotions());
    requests.push(await this.props.fetchHomePageProgressivePrizes());
    requests.push(await this.props.fetchHomePagePromotions());
    requests.push(await this.props.fetchRewardCategories());
    await Promise.all(requests);
  };

  public componentWillUnmount = async () => {
    if (!!this.prizeCheck) {
      clearInterval(this.prizeCheck);
    }
    this.prizeCheck = null;
  };

  public updateProgressivePrize = async () => {
    if (this.props.progressive && this.props.progressive.length > 0) {
      await this.props.fetchHomePageProgressivePrizes();
    } else {
      if (!!this.prizeCheck) {
        clearInterval(this.prizeCheck);
      }
      this.prizeCheck = null;
    }
  };

  public render(): JSX.Element {
    const { classes, authorizedFlags } = this.props;
    const { fetching } = this.state;
    return !fetching ? (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <h1 style={{ display: 'none' }}>Home Page</h1>
        {this.renderFeaturedSlide()}
        <div className={classNames(classes.main, classes.mainRaised, classes.container)}>
          {this.renderIntroSection()}
          {this.renderRewardsSection()}
          {this.renderEarnSection()}
          {this.renderDrawsSection()}
          {!!authorizedFlags && authorizedFlags.includes('INFORMATION_ONLY_V1') ? this.renderInformationOnlySection() : null}
          {!!authorizedFlags && authorizedFlags.includes('LL_PAYMENT_ENABLED') ? this.renderLuckyLoungeSection() : null}
        </div>
      </div>
    ) : (
      <AppSpinner label={'Loading'} />
    );
  }

  private renderIntroSection = (): React.ReactNode => <IntroSection />;

  private renderFeaturedSlide = (): React.ReactNode => {
    const { advertisements } = this.props;
    const featuredContent = advertisements.map(advert => ({
      id: advert.id,
      title: advert.title,
      image: advert.imageUrl,
      btnText: advert.buttonText,
      url: advert.buttonUrl,
      target: advert.buttonTarget,
      backgroundImage: advert.backgroundImage,
      textColor: advert.textColor
    }));
    if (!!advertisements && advertisements.length > 0) {
      return <FeaturedSlider content={featuredContent} />;
    }
    return null;
  };

  private renderRewardsSection = (): React.ReactNode => {
    const { rewardsFilter } = this.props;
    if (!!rewardsFilter && rewardsFilter.length > 0) {
      return (
        <RewardsSection
          rewards={rewardsFilter}
          category={this.props.categoryItems}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
        />
      );
    }
    return null;
  };

  private renderLuckyLoungeSection = (): React.ReactNode => <LuckyLoungeSection onCardClicked={this.navigateToLuckyLounge} />;

  private renderInformationOnlySection = (): React.ReactNode => (
    <InformationOnlySection onCardClicked={this.navigateToInformationOnlyList} />
  );

  private renderEarnSection = (): React.ReactNode => {
    if (!!homeScreenEarnActivities && homeScreenEarnActivities.length > 0) {
      return (
        <EarnSection
          activities={homeScreenEarnActivities}
          earnActivities={this.props.earnActivities.getActivitiesListSorted()}
          onEarnItemClicked={this.onEarnItemClicked}
        />
      );
    }
    return null;
  };

  private onCardClicked = (promotion: IPromotion): void => {
    this.props.selectPromotion(promotion);
    this.props.history.push('/promotion/' + promotion.id);
  };

  private navigateToLuckyLounge = () => {
    this.props.history.push('/lucky-lounge');
  };

  private navigateToInformationOnlyList = () => {
    this.props.history.push('/information-list');
  };

  private renderDrawsSection = (): React.ReactNode => {
    const { promotions, progressive } = this.props;
    if (!!promotions && promotions.length > 0) {
      return <DrawsSection promotions={promotions} progressive={progressive} onDrawCardClicked={this.onCardClicked} />;
    }
    return null;
  };

  private onEarnItemClicked = (activity: IActivity | Activity): void => {
    this.props.getActivity(activity.id);
    utils.navigateToActivityDetails(activity, this.props.history);
  };
}

const mapStateToProps = ({
  authentication: { account, accessToken },
  loyalty: { rewards, userRewards, rewardCategories, userActivities },
  feature: { authorizedFlags },
  media: { advertisements },
  authentication,
  draws: { homePromotions, homeProgressivePromotions }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  const currentRewards = loggedIn ? userRewards : rewards;

  const categoryItems: IRewardCategory[] = currentRewards ? currentRewards.filterCategories(rewardCategories) : [];

  const rewardsFilter: IReward[] = !!currentRewards ? (currentRewards as IReward[]) : [];
  return {
    categoryItems,
    rewards: currentRewards,
    earnActivities: userActivities,
    rewardsFilter,
    authentication,
    promotions: homePromotions,
    progressive: homeProgressivePromotions,
    loggedIn,
    advertisements,
    authorizedFlags
  };
};

const mapDispatchToProps = {
  fetchRewardCategories,
  fetchUserRewards,
  fetchRewards,
  fetchActivities,
  fetchUserActivitiesWithInterests,
  fetchHomePagePromotions,
  fetchHomePageProgressivePromotions,
  fetchHomePageProgressivePrizes,
  toggleLoading,
  clearLoading,
  selectPromotion,
  isAuthenticated,
  getAdvertisements,
  getActivity,
  showMessageBar
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(HomeScreen))));
