import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IReward } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import RedeemConfirmationModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemConfirmationModal';
import RedeemModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemModal';
import RewardCodesModal from '@pbl/pbl-react-web-components/lib/reward-detail/RewardCodesModal';
import RewardCouponModal from '@pbl/pbl-react-web-components/lib/reward-detail/RewardCouponModal';
import RewardDetail from '@pbl/pbl-react-web-components/lib/reward-detail/RewardDetail';
import RewardDigitalDownloadModal from '@pbl/pbl-react-web-components/lib/reward-detail/RewardDigitalDownloadModal';
import styles from 'assets/jss/modules/reward-detail/RewardDetailScreenStyle';
import constants from 'config/constants';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  fetchReward,
  fetchRewardEntries,
  fetchRewards,
  fetchUserRewards,
  redeemReward,
  resetError,
  resetState,
  setSelectedReward
} from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

interface IMatchParams {
  rewardId: string;
}

export interface IProps extends PropsConnected, WithTranslation, WithStyles<typeof styles>, RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IState {
  showModal: boolean;
  showModalConfirmation: boolean;
  redeemQuantity: number;
  showCodesModal: boolean;
  showDigitalDownloadModal: boolean;
  loading: boolean;
}

type PropsConnected = ConnectedProps<typeof connector>;

class RewardDetailScreen extends React.Component<IProps, IState> {
  public state = {
    showModal: false,
    showModalConfirmation: false,
    redeemQuantity: 0,
    showCodesModal: false,
    showDigitalDownloadModal: false,
    loading: false,
    category_name: ''
  };

  public componentDidMount = async () => {
    const {
      match: { params }
    } = this.props;
    if (params.rewardId.length > 0) {
      await this.props.isAuthenticated();
      await this.props.fetchRewardEntries(params.rewardId);
      await this.loadReward();
    } else {
      await this.showInvalidRewardError();
    }
  };

  onNavigate = async () => {
    const { history } = this.props;
    await history.push('/rewards');
  };

  public componentWillUnmount = async () => {
    await this.props.resetState();
    await this.props.clearLoading();
  };

  public render(): React.ReactNode {
    const { selectedReward, redeemablePoints, rewardEntries, authorizedFlags, t } = this.props;
    if (!selectedReward) {
      return null;
    }
    const { location } = this.props;
    const locationState: any = location && location.state ? location.state : {};
    return (
      <div>
        <ScrollToTopOnMount />
        <RedeemConfirmationModal
          isVisible={this.state.showModalConfirmation}
          onClose={this.closeModalConfirmationDialog}
          maxWidth="sm"
          email="winners@azlottery"
          text={t('rewards.confirmationMessage')}
        />
        <RewardDetail
          maxRedeemAvailable={this.props.maxRedeemAvailable}
          onRedeem={this.onRedeem}
          isLoggedIn={this.props.loggedIn}
          redeemablePoints={redeemablePoints}
          selectedReward={selectedReward}
          entries={rewardEntries}
          onNavigate={this.onNavigate}
          authorizedFlags={authorizedFlags}
          loading={this.state.loading}
          category_name={locationState.category_name}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
        />
        {this.getRedeemModal()}
        {this.getCodesModal()}
        {this.getDigitalDownloadModal()}
      </div>
    );
  }

  private getDigitalDownloadModal = (): React.ReactNode => {
    if (this.props.loggedIn && this.state.showDigitalDownloadModal) {
      const { selectedReward, rewardDigitalDownload } = this.props;
      if (!selectedReward) {
        return null;
      }
      return (
        <RewardDigitalDownloadModal
          title={selectedReward.title}
          open={this.state.showDigitalDownloadModal}
          downloadLink={rewardDigitalDownload as string}
          onClose={this.navigateWithRedeemSuccess}
        />
      );
    }
    return null;
  };

  private getRedeemModal = (): React.ReactNode => {
    if (this.props.loggedIn && this.state.showModal) {
      const { selectedReward } = this.props;
      if (!selectedReward && this.props.maxRedeemAvailable <= 0) {
        return null;
      }
      const isCouponReward = selectedReward && selectedReward.total_coupons && selectedReward.total_coupons > 0;
      return (
        <RedeemModal
          maxRedeemAvailable={this.props.maxRedeemAvailable}
          onRedeem={this.requestRedeem}
          onError={this.showRedeemError}
          onQuantityChange={this.onRedeemQuantityChange}
          redeemablePoints={this.props.redeemablePoints}
          open={this.state.showModal}
          quantity={this.state.redeemQuantity}
          selectedReward={selectedReward as IReward}
          rewardTerms={selectedReward && selectedReward.reward_terms}
          onClose={this.onModalClose}
          hideRedeemableQuantity={true}
          disableSlider={!!isCouponReward}
        />
      );
    }
    return null;
  };

  private getCodesModal = (): React.ReactNode => {
    if (this.props.loggedIn && this.state.showCodesModal) {
      const { selectedReward } = this.props;
      if (!selectedReward) {
        return null;
      }
      if (
        this.props.rewardCodes &&
        this.props.rewardCodes.length === 1 &&
        this.props.rewardCodes[0].codeType === 'COUPON' &&
        this.props.barcodeData
      ) {
        return (
          <RewardCouponModal
            open={this.state.showCodesModal}
            barcodeData={this.props.barcodeData}
            selectedReward={selectedReward}
            onClose={this.navigateWithRedeemSuccess}
          />
        );
      } else {
        return (
          <RewardCodesModal
            open={this.state.showCodesModal}
            email={`${this.props.userEmail}`}
            rewardCodes={this.props.rewardCodes}
            selectedReward={selectedReward}
            onClose={this.navigateWithRedeemSuccess}
          />
        );
      }
    }
    return null;
  };

  private loadReward = async () => {
    const {
      match: {
        params: { rewardId }
      },
      loggedIn,
      selectedReward
    } = this.props;
    await this.setState({ loading: true });
    await this.props.toggleLoading({
      spinning: true
    });
    document.title = !!selectedReward ? `${selectedReward.title}` : 'Reward Details';
    if (rewardId && !selectedReward) {
      await this.props.fetchReward(rewardId);
    }
    if (loggedIn && selectedReward) {
      await this.props.fetchRewardEntries(rewardId);
    }
    await this.setState({ loading: false });
    await this.props.clearLoading();
  };

  private showInvalidRewardError = async () => {
    await this.navigateToRewards({
      message: `This reward is not available, please try again!`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private showRedeemError = async (errorMessage: any) => {
    await this.props.showMessageBar({
      message: errorMessage,
      type: 'error',
      messageTimeout: 10000
    });
  };

  private getErrorMessage = (error: any) => {
    if (error) {
      if (error.status === 'FORBIDDEN') {
        if (!!error.message) return error.message;
        return error.error;
      }
    }
  };

  private showRedeemSuccess = async () => {
    const { rewardCodes, rewardDigitalDownload } = this.props;
    if (!!rewardCodes && rewardCodes.length > 0) {
      await this.setState({ showCodesModal: true });
      return;
    }

    if (!!rewardDigitalDownload) {
      await this.setState({ showDigitalDownloadModal: true });
      return;
    }
    await this.navigateWithRedeemSuccess();
  };

  private navigateWithRedeemSuccess = async () => {
    await this.onModalClose();
    if (this.props.selectedReward && !!this.props.selectedReward.sweepstake_winners) {
      this.setState({ showModalConfirmation: true });
    } else {
      await this.navigateToRewards({
        message: `Congratulations! You have successfully redeemed reward.`,
        type: 'success'
      });
    }
  };

  public closeModalConfirmationDialog = async () => {
    this.setState({ showModalConfirmation: false });
    await this.navigateToRewards();
  };

  private navigateToRewards = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/rewards', { message });
  };

  private onRedeem = async () => {
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    if (this.props.selectedReward?.isLuckyLoungeInfo) {
      this.props.history.push('/lucky-lounge/store');
      return;
    }
    await this.props.clearMessages();
    this.setState({ showModal: true, redeemQuantity: 1 });
  };

  private requestRedeem = async () => {
    const { selectedReward } = this.props;

    if (!selectedReward) {
      return;
    }
    await this.props.toggleLoading({
      spinning: true
    });
    await this.setState({ showModal: false });
    await this.props.resetError();
    await this.props.redeemReward(selectedReward.id, this.state.redeemQuantity);

    setTimeout(async () => {
      const { errorMessage } = this.props;
      if (errorMessage) {
        await this.showRedeemError(this.getErrorMessage(errorMessage));
      } else {
        await this.showRedeemSuccess();
      }
      await this.props.resetError();
      await this.props.clearLoading();
    }, 1000);
  };

  private onRedeemQuantityChange = (quantity: number) => {
    this.setState({ redeemQuantity: quantity });
  };

  private onModalClose = () => {
    this.setState({ showModal: false, showCodesModal: false, showDigitalDownloadModal: false });
  };
}

const mapStateToProps = ({
  authentication: { account, accessToken },
  feature: { authorizedFlags },
  loyalty: {
    selectedReward,
    userRewards,
    rewards,
    userPoints,
    errorMessage,
    rewardCodes,
    rewardDigitalDownload,
    loading,
    rewardEntries,
    barcodeData
  }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  const currentRewards = loggedIn ? userRewards : rewards;

  const userEmail: string = loggedIn ? account.email : '';
  const redeemablePoints = loggedIn ? userPoints.redeemablePoints : 0;

  let maxRedeemAvailable = 0;

  if (!!selectedReward) {
    maxRedeemAvailable = selectedReward.max_select_quantity || selectedReward.max_per_user || constants.REWARDS_MAXIMUM;

    if (selectedReward.extra_data && selectedReward.extra_data.max_limit && maxRedeemAvailable > selectedReward.extra_data.max_limit) {
      maxRedeemAvailable = selectedReward.extra_data.max_limit;
    } else if (maxRedeemAvailable > constants.REWARDS_MAXIMUM) {
      maxRedeemAvailable = constants.REWARDS_MAXIMUM;
    }

    if (selectedReward.freq_cap != null && maxRedeemAvailable > selectedReward.freq_cap) {
      maxRedeemAvailable = selectedReward.freq_cap;
    }

    if (selectedReward.current_quantity != null && maxRedeemAvailable > selectedReward.current_quantity) {
      maxRedeemAvailable = selectedReward.current_quantity;
    }

    if (maxRedeemAvailable > redeemablePoints / selectedReward.num_points) {
      maxRedeemAvailable = Math.floor(redeemablePoints / selectedReward.num_points);
    }
  }

  return {
    loading,
    errorMessage,
    rewards: currentRewards,
    loggedIn,
    authorizedFlags,
    selectedReward,
    rewardCodes,
    rewardDigitalDownload,
    userEmail,
    redeemablePoints,
    maxRedeemAvailable,
    rewardEntries,
    barcodeData
  };
};

const mapDispatchToProps = {
  setSelectedReward,
  fetchUserRewards,
  showMessageBar,
  clearMessages,
  resetState,
  toggleLoading,
  clearLoading,
  redeemReward,
  resetError,
  fetchRewards,
  isAuthenticated,
  fetchRewardEntries,
  fetchReward
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTranslation()(RewardDetailScreen))));
